 import React from "react";

function Kenan() {


  return (
    <div className="Kenan"> 


<div style={{backgroundColor:'red',margin:'20px',padding:'50px', textAlign:'center',bottom:0,flexDirection:'column',flex:1}}>
Kenan
</div>
</div>
  );
  }

export default Kenan;
