import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App"
import Kenan from "./Kenan"

ReactDOM.render(
  <App/>,
    document.getElementById('dima')

);
ReactDOM.render(
  <Kenan/>,
    document.getElementById('kenan')

);
